<template>
    <footer id="contact">
        <div>
            <router-link to="/" class="logo">
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 451.5 375.75">
                    <polygon points="291.94 75 201.77 75 260.59 15 351.09 15 291.94 75"/>
                    <polygon points="260.93 140 351.09 140 292.27 80 201.77 80 260.93 140"/>
                    <polygon points="292.41 205 202.24 205 261.06 145 351.57 145 292.41 205"/>
                    <polygon points="138.09 270 47.92 270 106.74 210 197.24 210 138.09 270"/>
                    <g>
                        <path d="M128.9,354.15l-2.18,10.52h-6.55l5.93-28.79h11.86c7.44,0,11.86,2.76,11.86,7.68,0,6.68-6.29,10.6-15.6,10.6h-5.3Zm2.86-14.04l-2.03,9.8h5.62c4.63,0,7.8-2.12,7.8-5.72,0-2.64-2.29-4.08-6.03-4.08h-5.36Z"/>
                        <path d="M177.05,364.67l2.55-12.48h-16.95l-2.55,12.48h-6.55l5.93-28.79h6.55l-2.5,12.12h16.95l2.5-12.12h6.55l-5.93,28.79h-6.55Z"/>
                        <path d="M213.77,335.43h.21l11.7,29.24h-6.86l-2.13-5.72h-15.29l-4.52,5.72h-6.86l23.77-29.24Zm1.56,19.76c-1.4-3.68-2.5-6.92-3.38-10.2-2.24,3-4.94,6.68-7.64,10.2h11.02Z"/>
                        <path d="M230.66,363.54l1.04-4.92c2.44,1.04,5.82,2,9.57,2.08,1.35,0,2.65-.08,3.8-.32,3.02-.52,4.94-1.68,4.94-3.6s-1.98-3.16-6.86-4.56c-5.72-1.64-8.84-3.72-8.84-7.52,0-5.84,6.55-9.36,14.87-9.36,3.74,0,7.18,.72,9.26,1.44l-.94,4.72c-1.98-.92-5.36-1.72-8.74-1.72-4.63,0-7.96,1.52-7.96,4.04,0,1.76,1.87,3.08,6.76,4.52,5.72,1.72,8.94,3.8,8.94,7.56,0,5.68-5.88,9.28-15.18,9.28-4.32,0-8.22-.8-10.66-1.64Z"/>
                        <path d="M262.96,364.67l5.93-28.79h23.56l-.88,4.24h-17l-1.61,7.92h14.41l-.83,4.12h-14.41l-1.72,8.28h17.79l-.88,4.24h-24.34Z"/>
                        <path d="M306.01,358.95c1.61,1,4.47,1.8,7.9,1.8,4.89,0,8.89-2.28,8.89-6,0-2.8-2.91-4.48-7.12-4.48-3.74,0-6.4,.76-7.38,1.08l4-15.48h19.03l-.88,4.28h-13.36l-1.66,6.68c.62-.16,1.77-.4,3.64-.4,6.19,0,10.45,3.2,10.45,8.08,0,6.6-6.86,10.68-15.76,10.68-4.06,0-6.97-.84-8.68-1.8l.94-4.44Z"/>
                    </g>
                </svg>
            </router-link>
            <div class="signature">
                <p><strong>ROSSIER Gilles</strong></p>
                <p>Founder, directeur des travaux diplômé</p>
            </div>
            <div class="icones">
                <a class="icone"  href="https://www.linkedin.com/in/gilles-rossier-911aa0192/" target="_blank">
                    <svg xmlns="http://www.w3.org/2000/svg" width="22.5" height="22.5" viewBox="0 0 21.328 21.327">
                        <path d="M4.774,21.337H.352V7.1H4.774ZM2.561,5.156A2.573,2.573,0,1,1,5.121,2.571,2.582,2.582,0,0,1,2.561,5.156ZM21.323,21.337H16.911V14.406c0-1.652-.033-3.77-2.3-3.77-2.3,0-2.651,1.795-2.651,3.651v7.05H7.544V7.1h4.241V9.04h.062a4.646,4.646,0,0,1,4.184-2.3c4.475,0,5.3,2.947,5.3,6.774v7.822Z"/>
                    </svg>
                </a>
                <a class="icone" href="https://www.instagram.com/phase_5/" target="_blank">
                    <svg xmlns="http://www.w3.org/2000/svg" height="26.5" width="26.5" viewBox="0 0 448 512">
                        <path  d="M224.1 141c-63.6 0-114.9 51.3-114.9 114.9s51.3 114.9 114.9 114.9S339 319.5 339 255.9 287.7 141 224.1 141zm0 189.6c-41.1 0-74.7-33.5-74.7-74.7s33.5-74.7 74.7-74.7 74.7 33.5 74.7 74.7-33.6 74.7-74.7 74.7zm146.4-194.3c0 14.9-12 26.8-26.8 26.8-14.9 0-26.8-12-26.8-26.8s12-26.8 26.8-26.8 26.8 12 26.8 26.8zm76.1 27.2c-1.7-35.9-9.9-67.7-36.2-93.9-26.2-26.2-58-34.4-93.9-36.2-37-2.1-147.9-2.1-184.9 0-35.8 1.7-67.6 9.9-93.9 36.1s-34.4 58-36.2 93.9c-2.1 37-2.1 147.9 0 184.9 1.7 35.9 9.9 67.7 36.2 93.9s58 34.4 93.9 36.2c37 2.1 147.9 2.1 184.9 0 35.9-1.7 67.7-9.9 93.9-36.2 26.2-26.2 34.4-58 36.2-93.9 2.1-37 2.1-147.8 0-184.8zM398.8 388c-7.8 19.6-22.9 34.7-42.6 42.6-29.5 11.7-99.5 9-132.1 9s-102.7 2.6-132.1-9c-19.6-7.8-34.7-22.9-42.6-42.6-11.7-29.5-9-99.5-9-132.1s-2.6-102.7 9-132.1c7.8-19.6 22.9-34.7 42.6-42.6 29.5-11.7 99.5-9 132.1-9s102.7-2.6 132.1 9c19.6 7.8 34.7 22.9 42.6 42.6 11.7 29.5 9 99.5 9 132.1s2.7 102.7-9 132.1z"/>
                    </svg>
                </a>
            </div>
        </div>
        <div>
            <div>
                <p v-if="this.lang === 'fr'"><strong>Adresse</strong></p>
                <p v-else><strong>Addresse</strong></p>
                <p>co/PHASE5</p> 
                <p>Le Bivouac</p> 
                <p>Route de Verbier Station 45</p> 
                <p>CH-1936 Verbier</p> 
            </div>
        </div>
        <div>
            <div>
                <p v-if="this.lang === 'fr'"><strong>Téléphone</strong></p>
                <p v-else><strong>Phone number</strong></p>
                <a href="tel:+41 79 586 96 78">+41 79 586 96 78</a>
            </div>
            <div>
                <p><strong>E-mail</strong></p>
                <a href="mailto:gilles@phase-5.ch">gilles@phase-5.ch</a>
            </div>
        </div>
        <div  v-if="this.lang === 'fr'" class="real">
            ©2024 Phase 5 - Site réalisé par <a href="https://www.abclic.ch" target="_blank">Abclic</a> et <a href="https://www.ananaweb.ch" target="_blank">Ananaweb</a>
        </div>
        <div  v-else class="real">
            ©2024 Phase 5 - Website realized by <a href="https://www.abclic.ch" target="_blank">Abclic</a> and <a href="https://www.ananaweb.ch" target="_blank">Ananaweb</a>
        </div>
    </footer>
</template>

<script>
import { mapState } from 'vuex';

export default ({
    name: "FooterLayout",
    computed:mapState([
      'lang'
    ]),
})
</script>

<style lang="scss" scoped>
@import "../../styles/includes.scss";

footer{
    position: relative;
    display: flex;
    flex-flow: column wrap;
    background-color: $black;
    padding: 10%;
    padding-bottom: 0;
    color: $white;
    z-index: 10;
    @include tablet{
        flex-flow: row wrap;
        box-sizing: border-box;
        max-height: inherit;
        padding: 25% 14.5% 0 14.5%;
        justify-content: space-between;
        z-index: inherit;
        &>div:nth-child(1),>div:nth-child(2){
            border-right: 1px solid $white;
        }
    }
    @include tablet-portrait{
        height: auto;
    }
    .logo{
        display: block;
        width: clamp(250px, 80%, 300px);
        path,polygon{
            fill: $white;
        }
    }
    .logo,.signature{
        display: none;
        @include tablet{
            display:block;
        }
    }
    &>div:not(.real){
        display: flex;
        flex-direction: column;
        padding-bottom: 1.5rem;
        padding-right: 5vw;
        gap: 1rem;
        font-style: normal;
        font-size: clamp(16px, 1.3vw, 24px);
        @include smartphone-landscape{
            gap: 2rem;
        }
        @include tablet{
            padding-bottom: 3rem;
            gap: 2rem;
        }
        & div{
            flex: 0 0 auto;
        }
        & p{
            margin: 0.5rem 0;
        }
    }
    & .icones{
        display: flex;
        flex-flow: row nowrap;
        min-width: 10%;
        gap: clamp(2.5%,16px,10%);
        @include smartphone-landscape{
            min-width: 20%;
        }
        @include tablet-portrait{
            min-width: 20%;
        }
    }
    & .real{
        width: 100%;
        text-align: center;
        font-size: clamp(12px, 1.2vw, 17px);
        border-top: 1px solid $white;
        padding: 1.5rem 0;
        @include tablet{
            padding: 2rem 0;
        }
    }
}
</style>