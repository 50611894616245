<template>
    <LoaderView/>
    <div class="filter-bar">
        <Skeletor v-if="$apollo.queries.tags.loading" as="div"/>
        <template v-else>
            <button @click="showFilters">
                <template v-if="this.lang === 'fr' ">FILTRES</template>
                <template v-else>FILTERS</template>
                <svg ref="arrow" xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 320 512">
                    <path d="M137.4 374.6c12.5 12.5 32.8 12.5 45.3 0l128-128c9.2-9.2 11.9-22.9 6.9-34.9s-16.6-19.8-29.6-19.8L32 192c-12.9 0-24.6 7.8-29.6 19.8s-2.2 25.7 6.9 34.9l128 128z"/>
                </svg>
            </button>
            <ul ref="filters" class="filters">
                <li v-if="this.lang === 'fr' " id="all" @click="hanldeFilterClick">TOUT</li>
                <li v-else id="all" @click="hanldeFilterClick">ALL</li>
                <li  v-for="(tag,index) in this.tags.enumValues" :key="index" @click="hanldeFilterClick" :id="tag.name">{{ tag.name }}</li>
            </ul>
        </template>
    </div>

    <div class="projets">
        <ul v-if="$apollo.queries.projets.loading">
            <Skeletor as="div" width="100%" />
            <Skeletor as="div" width="100%" />
            <Skeletor as="div" width="100%" />
            <Skeletor as="div" width="100%" />
            <Skeletor as="div" width="100%" />
            <Skeletor as="div" width="100%" />
            <Skeletor as="div" width="100%" />
            <Skeletor as="div" width="100%" />
            <Skeletor as="div" width="100%" />
        </ul>
        <ul v-else>
            <li v-for="(projet, index) in this.projets" :key="index">
                <router-link :to="`/projet/${projet.slug}`"  @mousemove="handleMouseMove"  @mouseleave="handleMouseLeave">{{ projet.title }}</router-link>
                <img :src="projet.images[0].url"/>
            </li>
        </ul>
    </div>
</template>

<script>
import gql from 'graphql-tag';
import LoaderView from "../components/LoaderView.vue";
import gsap from 'gsap';
import { mapState } from 'vuex';

export default {
    name:"ProjetsView",
    components: {
    LoaderView,
    },
    apollo: {
        tags: gql`
            query tags {
                tags : __type(name: "Tags") {
                enumValues {
                name
                }
            }
        }`,
        projets: {
            query: gql`query projets($tags: [Tags!]!) {
                projets(where: {tag_contains_all: $tags},orderBy: realisationDate_DESC) {
                    id
                    title
                    slug
                    images {
                        url
                    }
                }
            }`,
            variables() {
                return{
                    tags: []
                }
            }
        }
    },
    computed:mapState([
      'lang'
    ]),
    data(){
        return{
            media : gsap.matchMedia(),
            filtreState : false,
        }
    },
    methods:{
        handleMouseMove(e){
            const x = e.clientX;
            const y = e.clientY;
            const img = e.target.nextSibling;
            const width = gsap.getProperty(img,'width') / 2;

            this.media.add("(min-width: 900px)",()=>{
                gsap.to(img,{
                    autoAlpha:1,
                    x:x - width,
                    y:y - width
                });
            });
        },
        handleMouseLeave(e){
            const img = e.target.nextSibling;
            this.media.add("(min-width: 900px)",()=>{
                gsap.to(img,{
                    autoAlpha:0,
                });
            });
        },
        showFilters(){
            this.media.add("(min-width: 900px)",()=>{
                if(this.filtreState){
                    gsap.timeline()
                    .to(this.$refs.filters,{
                        x:"-120%"
                    },"0")
                    .to(this.$refs.arrow,{rotate:-90},"0");
                }else{
                    gsap.timeline()
                    .to(this.$refs.filters,{
                        x:0
                    },"0")
                    .to(this.$refs.arrow,{rotate:90},"0");
                }
            });

            this.media.add("(max-width: 899px)",()=>{
                if(this.filtreState){
                    gsap.timeline()
                    .to(this.$refs.filters,{
                        y:"-150%"
                    },"0")
                    .to(this.$refs.arrow,{rotate:0},"0");
                }else{
                    gsap.timeline()
                    .to(this.$refs.filters,{
                        y:0
                    },"0")
                    .to(this.$refs.arrow,{rotate:180},"0");
                }

            });
            this.filtreState = !this.filtreState;
        },
        hanldeFilterClick(e){
            if(window.innerWidth<900) this.showFilters();
            let id = [e.target.id];
            if(id[0] === 'all') id = [];
            this.$apollo.queries.projets.refetch({
                tags: id
            });
        }
    }
}
</script>

<style lang="scss" scoped>
    @import "../styles/includes.scss";

    .filter-bar{
        position: fixed;
        top: 0;
        width: 100%;
        margin: auto;
        padding-top: 30%;
        z-index: 1;
        text-align: center;
        background-color: $white;
        @include smartphone-landscape{
            padding-top: 10%;
        }
        @include tablet{
            left: 50%;
            width: 72%;
            padding-top: 11%;
            transform: translateX(-50%);
            margin-inline: auto;
            text-align: left;
            contain: paint;
        }
        & button{
            position: relative;
            display: flex;
            justify-content: center;
            align-items: center;
            width: 100%;
            padding: 2vh 0;
            font-size: clamp(18px, 1.2vw, 28px);
            text-transform: uppercase;
            letter-spacing: 0px;
            color: $black;
            transition: all 0.25s ease;
            @include tablet{
                &:hover{
                    letter-spacing: 1.5px;
                    transition: all 0.25s ease;
                }
            }
            & svg{
                fill: $black;
                @include tablet{
                    transform: rotate(-90deg);
                }
            }
        }
        & .filters{
            position: absolute;
            top: 0;
            left: 50%;
            transform: translateX(-50%) translateY(-150%);
            display:flex;
            flex-flow: column nowrap;
            justify-content: space-between;
            align-items: center;
            gap: 4vh;
            width: 100vw;
            margin: 0;
            margin-top: 40%;
            padding: 10% 0;
            font-size: clamp(14px, 4.2vw, 18px);
            text-transform: uppercase;
            letter-spacing: 0px;
            color: $black;
            background-color: $white;
            z-index: 5;
            @include smartphone-landscape{
                margin-top: 13%;
                flex-flow: row wrap;
                width: 72vw;
            }
            @include tablet {
                position: relative;
                left: 0;
                transform: translateX(-120%);
                flex-flow: row nowrap;
                align-items: flex-start;
                gap: 1vw;
                width: 100%;
                min-height: inherit;
                margin-top: 0;
                padding: 3vw 0;
                font-size: clamp(14px, 1.3vw, 22px);
                color: $black;
                background-color: inherit;
                z-index: 1;
            }
            @include tablet-portrait{
                flex-flow: row wrap;
            }
            & li{
                cursor: pointer;
                transition: all 0.25s ease;
                &:hover{
                letter-spacing: 1.5px;
                transition: all 0.25s ease;
                }
                &:active{
                    text-decoration: underline;
                }
            }
        }
    }
    .projets{
        width: 72%;
        margin-inline: auto;
        margin-top: 50%;
        font-size: clamp(18px, 1.9vw, 35px);
        text-transform: uppercase;
        color: $black;
        @include smartphone-landscape{
            margin-top: 5%;
        }
        @include tablet{
            margin-top: 11%;
        }
        & li{
            position: relative;
            border-top: 1px solid $black;
            max-width: 100%;
            cursor: pointer;
            &>a{
                position: relative;
                display: block;
                letter-spacing: 2px;
                padding: 1rem 0;
                @include tablet{
                    padding: 3vw 0;
                    letter-spacing: 4px;
                    &:hover{
                        z-index: 11;
                    }
                }
                &:active{
                    background: $black;
                    color:$white;
                }
            }
            &>img{
                display: none;
                @include tablet{
                    display: block;
                    position: fixed;
                    top: 0%;
                    left: 0%;
                    width: 26.042vw;
                    aspect-ratio: 3/2;
                    clip-path: polygon(80% 100%, 100% 0%, 20% 0%, 0% 100%);
                    height: auto;
                    background: $black;
                    contain: paint;
                    pointer-events: none;
                    opacity: 0;
                    visibility: hidden;
                    z-index: 10;
                    object-fit: cover;
                }
            }
        }
    }
</style>
  