<template>
  <div ref="loader" class="loader">
    <div ref="svg" class="loader-container">
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 451.5 475.75">
            <polygon points="291.94 75 201.77 75 260.59 15 351.09 15 291.94 75"/>
            <polygon points="260.93 140 351.09 140 292.27 80 201.77 80 260.93 140"/>
            <polygon points="292.41 205 202.24 205 261.06 145 351.57 145 292.41 205"/>
            <polygon points="138.09 270 47.92 270 106.74 210 197.24 210 138.09 270"/>
            <g>
                <path class="letter" d="M128.9,354.15l-2.18,10.52h-6.55l5.93-28.79h11.86c7.44,0,11.86,2.76,11.86,7.68,0,6.68-6.29,10.6-15.6,10.6h-5.3Zm2.86-14.04l-2.03,9.8h5.62c4.63,0,7.8-2.12,7.8-5.72,0-2.64-2.29-4.08-6.03-4.08h-5.36Z"/>
                <path class="letter" d="M177.05,364.67l2.55-12.48h-16.95l-2.55,12.48h-6.55l5.93-28.79h6.55l-2.5,12.12h16.95l2.5-12.12h6.55l-5.93,28.79h-6.55Z"/>
                <path class="letter" d="M213.77,335.43h.21l11.7,29.24h-6.86l-2.13-5.72h-15.29l-4.52,5.72h-6.86l23.77-29.24Zm1.56,19.76c-1.4-3.68-2.5-6.92-3.38-10.2-2.24,3-4.94,6.68-7.64,10.2h11.02Z"/>
                <path class="letter" d="M230.66,363.54l1.04-4.92c2.44,1.04,5.82,2,9.57,2.08,1.35,0,2.65-.08,3.8-.32,3.02-.52,4.94-1.68,4.94-3.6s-1.98-3.16-6.86-4.56c-5.72-1.64-8.84-3.72-8.84-7.52,0-5.84,6.55-9.36,14.87-9.36,3.74,0,7.18,.72,9.26,1.44l-.94,4.72c-1.98-.92-5.36-1.72-8.74-1.72-4.63,0-7.96,1.52-7.96,4.04,0,1.76,1.87,3.08,6.76,4.52,5.72,1.72,8.94,3.8,8.94,7.56,0,5.68-5.88,9.28-15.18,9.28-4.32,0-8.22-.8-10.66-1.64Z"/>
                <path class="letter" d="M262.96,364.67l5.93-28.79h23.56l-.88,4.24h-17l-1.61,7.92h14.41l-.83,4.12h-14.41l-1.72,8.28h17.79l-.88,4.24h-24.34Z"/>
                <path class="letter" d="M306.01,358.95c1.61,1,4.47,1.8,7.9,1.8,4.89,0,8.89-2.28,8.89-6,0-2.8-2.91-4.48-7.12-4.48-3.74,0-6.4,.76-7.38,1.08l4-15.48h19.03l-.88,4.28h-13.36l-1.66,6.68c.62-.16,1.77-.4,3.64-.4,6.19,0,10.45,3.2,10.45,8.08,0,6.6-6.86,10.68-15.76,10.68-4.06,0-6.97-.84-8.68-1.8l.94-4.44Z"/>
            </g>
          </svg>
      <div class="progress-bar">
        <div class="progress" :style="{ width: percentage + '%' }"></div>
      </div>
    </div>
    <div ref="veil" class="loader-veil"></div>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import gsap from 'gsap';


export default {
  name: 'LoaderView',
  computed:mapState([
      'documentReady'
    ]),
  data() {
    return {
      loading: true,
      percentage: 0,
      letters : null,
      keyframe : null
    };
  },
  mounted(){
    this.documentReady && this.setupRemoveLoader();

    this.letters = document.querySelectorAll(".letter");
    this.setupLetterAnimation();
    const speed = this.estimateConnectionSpeed();
    let increment = speed === 'slow' ? 2.5 : 5;
    let progress = 0;
    const interval = setInterval(() => {
      progress += Math.random() * increment;
      this.percentage = progress;
      if (progress >= 100) {
        clearInterval(interval);
        this.loading = false;
      }
    }, 100);
  },
  methods:{
    estimateConnectionSpeed() {
      const connection = navigator.connection || navigator.mozConnection || navigator.webkitConnection;
      if (connection) {
        switch (connection.effectiveType) {
          case 'slow-2g':
          case '2g':
          case '3g':
            return 'slow';
          case '4g':
            return 'fast';
          default:
            return 'unknown';
        }
      }
      return 'unknown';
    },
    setupLetterAnimation (){
      const animation = gsap.to(this.letters,{
      keyframes: {
                scaleY: [1, 2, 1],
                ease: "power1.inOut"
            },
      stagger:0.2,
      duration: 2,
      repeat:-1
    });
    this.keyframe = animation;
    },
    cleanupAnimations(){
      this.keyframe.kill();
    },
    setupRemoveLoader(){
      gsap.timeline({})
          .to(this.$refs.veil, { y:"-150%",duration:1.5},"0")
          .to(this.$refs.svg, { autoAlpha: 0,duration:0.5 },"0")
          .to(this.$refs.loader, { autoAlpha: 0,duration:1,zIndex:-1 },"1");
          document.querySelector("body").style.overflow = "overlay";
    }
  },
  beforeUnmount() {
    this.cleanupAnimations();
  },
  watch:{
    documentReady:{
      handler(documentReady) {
        if(documentReady){
          this.setupRemoveLoader()
        }
      }
    }
  }
}
</script>

<style lang="scss">
@import "../styles/includes.scss";

.loader{
    position: fixed;
    top: 0;
    left: 0;
    display:flex;
    justify-content:center;
    align-items:center;
    width: 100vw;
    height: 100vh;
    background-color: $white;
    z-index: 100;
    & svg{
      fill: $white;
      mix-blend-mode: exclusion;
      width: 60vw;
      @include tablet{
        width: 30vw;
      }
    }
}

.loader-text{
    display: flex;
    align-items: center;
    justify-content: center;
    color: $white;
    text-align: center;
}

.percentage {
  font-size: clamp(16px, 1.5vw, 32px);
  color: $black;
  mix-blend-mode: exclusion;
  margin-top: 1rem;
}

.progress-bar {
  width: 100%;
  height: 6.26px;
  fill: $white;
  mix-blend-mode: exclusion;
  margin: 0 auto;
  @include tablet{
    height: 13.26px;
  }
}

.progress {
  height: 100%;
  background-color: $white;
  transition: width 0.5s ease;
}

.loader-veil{
  position: absolute;
    top: 50%;
    left: 50%;
    transform: translateX(-50%) translateY(50%);
    background: $black;
    width: 100%;
    height: 100%;
}

</style>