<template>
  <header>
        <router-link to="/" class="logo" @click="closeMenu">
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 451.5 375.75">
            <polygon points="291.94 75 201.77 75 260.59 15 351.09 15 291.94 75"/>
            <polygon points="260.93 140 351.09 140 292.27 80 201.77 80 260.93 140"/>
            <polygon points="292.41 205 202.24 205 261.06 145 351.57 145 292.41 205"/>
            <polygon points="138.09 270 47.92 270 106.74 210 197.24 210 138.09 270"/>
            <g>
                <path d="M128.9,354.15l-2.18,10.52h-6.55l5.93-28.79h11.86c7.44,0,11.86,2.76,11.86,7.68,0,6.68-6.29,10.6-15.6,10.6h-5.3Zm2.86-14.04l-2.03,9.8h5.62c4.63,0,7.8-2.12,7.8-5.72,0-2.64-2.29-4.08-6.03-4.08h-5.36Z"/>
                <path d="M177.05,364.67l2.55-12.48h-16.95l-2.55,12.48h-6.55l5.93-28.79h6.55l-2.5,12.12h16.95l2.5-12.12h6.55l-5.93,28.79h-6.55Z"/>
                <path d="M213.77,335.43h.21l11.7,29.24h-6.86l-2.13-5.72h-15.29l-4.52,5.72h-6.86l23.77-29.24Zm1.56,19.76c-1.4-3.68-2.5-6.92-3.38-10.2-2.24,3-4.94,6.68-7.64,10.2h11.02Z"/>
                <path d="M230.66,363.54l1.04-4.92c2.44,1.04,5.82,2,9.57,2.08,1.35,0,2.65-.08,3.8-.32,3.02-.52,4.94-1.68,4.94-3.6s-1.98-3.16-6.86-4.56c-5.72-1.64-8.84-3.72-8.84-7.52,0-5.84,6.55-9.36,14.87-9.36,3.74,0,7.18,.72,9.26,1.44l-.94,4.72c-1.98-.92-5.36-1.72-8.74-1.72-4.63,0-7.96,1.52-7.96,4.04,0,1.76,1.87,3.08,6.76,4.52,5.72,1.72,8.94,3.8,8.94,7.56,0,5.68-5.88,9.28-15.18,9.28-4.32,0-8.22-.8-10.66-1.64Z"/>
                <path d="M262.96,364.67l5.93-28.79h23.56l-.88,4.24h-17l-1.61,7.92h14.41l-.83,4.12h-14.41l-1.72,8.28h17.79l-.88,4.24h-24.34Z"/>
                <path d="M306.01,358.95c1.61,1,4.47,1.8,7.9,1.8,4.89,0,8.89-2.28,8.89-6,0-2.8-2.91-4.48-7.12-4.48-3.74,0-6.4,.76-7.38,1.08l4-15.48h19.03l-.88,4.28h-13.36l-1.66,6.68c.62-.16,1.77-.4,3.64-.4,6.19,0,10.45,3.2,10.45,8.08,0,6.6-6.86,10.68-15.76,10.68-4.06,0-6.97-.84-8.68-1.8l.94-4.44Z"/>
            </g>
          </svg>
        </router-link>
        <div @click="handleBurgerClick(this.menuState)" class="burger">
          <svg ref="burger" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 38.11 38.69">
            <circle cx="4.94" cy="4.94" r="4.92"/>
            <circle cx="33.19" cy="4.94" r="4.92"/>
            <circle cx="19.03" cy="4.94" r="4.92" class="burger_hide_point"/>
            <circle cx="4.91" cy="31.77" r="4.92"/>
            <circle cx="33.19" cy="31.77" r="4.92"/>
            <circle cx="19.05" cy="31.77" r="4.92" class="burger_hide_point"/>
            <circle cx="4.87" cy="18.35" r="4.92" class="burger_hide_point"/>
            <circle cx="33.19" cy="18.35" r="4.92" class="burger_hide_point"/>
            <circle cx="19.03" cy="18.35" r="4.92"/>
        </svg>
        </div>
        <nav ref="navigation" class="navigation">
          <div ref="bar" class="top-bar">
            <div class="mode">
              <label class="toggle-btn">
                  <input class="toggle" type="checkbox"  @click="changeColor" :checked="this.mode === 'light' ? true : false" />
                  <span class="slider round"></span>
              </label>
              <span ref="color_mode">Sombre</span>
            </div>
            <div class="lang" :data-lang="this.lang" @click="changeLang">
             {{ this.lang === 'fr'? 'FR | EN' : 'EN | FR' }}
            </div>
          </div>
          <div class="block"></div>
          <ul>
            <li class="navlink-projets">
              <router-link to="/projets" @click="closeMenu">
                <span>P</span>
                <span>r</span>
                <span>o</span>
                <span>j</span>
                <span>e</span>
                <span>t</span>
                <span>s</span>
              </router-link>
            </li>
            <li class="navlink-office">
              <router-link to="/#office" @click="closeMenu">
                <span>O</span>
                <span>f</span>
                <span>f</span>
                <span>i</span>
                <span>c</span>
                <span>e</span>
              </router-link>
            </li>
            <li class="navlink-services">
              <router-link to="/#services" @click="closeMenu">
                <span>S</span>
                <span>e</span>
                <span>r</span>
                <span>v</span>
                <span>i</span>
                <span>c</span>
                <span>e</span>
                <span>s</span>
              </router-link >
            </li>
            <li class="navlink-references">
              <router-link to="/#references" @click="closeMenu">
                <span>R</span>
                  <span>
                    <template v-if="this.lang === 'fr'">é</template>
                    <template v-if="this.lang === 'en'">e</template>
                  </span>
                  <span>f</span>
                  <span>
                    <template v-if="this.lang === 'fr'">é</template>
                    <template v-if="this.lang === 'en'">e</template>
                  </span>
                  <span>r</span>
                  <span>e</span>
                  <span>n</span>
                  <span>c</span>
                  <span>e</span>
                  <span>s</span>

              </router-link>
            </li>
            <li class="navlink-contact">
              <router-link to="/#contact" @click="closeMenu">
                <span>C</span>
                <span>o</span>
                <span>n</span>
                <span>t</span>
                <span>a</span>
                <span>c</span>
                <span>t</span>
              </router-link>
            </li>
          </ul>
        </nav>
  </header>
</template>

<script>
import gsap from "gsap";
import { ScrollTrigger } from 'gsap/all';
import { mapState } from 'vuex';

gsap.registerPlugin(ScrollTrigger);

export default {
  name: 'MenuLayout',
  data() {
    return{
      menuState:false,
      burger_hide_point:null,
      letters : {
        projets:null,
        office:null,
        services:null,
        references:null,
        contact:null,
      }
    }
  },
  computed:mapState([
      'lang',
      'mode'
    ]),
  mounted() {
    this.burger_hide_point = document.querySelectorAll(".burger_hide_point");
    this.letters.projets = document.querySelectorAll(".navigation .navlink-projets span");
    this.letters.office = document.querySelectorAll(".navigation .navlink-office span");
    this.letters.services = document.querySelectorAll(".navigation .navlink-services span");
    this.letters.references = document.querySelectorAll(".navigation .navlink-references span");
    this.letters.contact = document.querySelectorAll(".navigation .navlink-contact span");
  },
  methods:{
    handleBurgerClick(state){
      state && this.closeMenu();
      !state && this.openMenu();
    },
    openMenu(){
      this.menuState = true;
      document.querySelector("body").style.overflow = "hidden";
      gsap.to(this.$refs.navigation,{width:"100vw", x:0,ease:"power1"});
      gsap.to(this.$refs.burger,{rotate:360});
      this.burger_hide_point.forEach(el=>gsap.to(el,{autoAlpha:0}));
      gsap.to(this.letters.projets,{x:0, ease: "power1",stagger:0.1,delay:0.1})
      gsap.to(this.letters.office,{x:0, ease: "power1",stagger:0.1,delay:0.2})
      gsap.to(this.letters.services,{x:0, ease: "power1",stagger:0.1,delay:0.3})
      gsap.to(this.letters.references,{x:0, ease: "power1",stagger:0.1,delay:0.4})
      gsap.to(this.letters.contact,{x:0, ease: "power1",stagger:0.1,delay:0.5})
      gsap.to(this.$refs.navigation,{"--height": "100%",delay:1})
      gsap.to(this.$refs.bar,{autoAlpha:1},"1")
    },
    closeMenu(){
      this.menuState = false;
      document.querySelector("body").style.overflow = "overlay";
      document.querySelector("body").style.overflowX = "hidden";
      gsap.to(this.$refs.bar,{autoAlpha:0})
      gsap.to(this.$refs.burger,{rotate:-360});
      this.burger_hide_point.forEach(el=>gsap.to(el,{autoAlpha:1}))
      gsap.to(this.$refs.navigation,{width:0, x:"-100%", "--height": 0, delay:0.5})
      gsap.to(this.letters.projets,{x:"-1500%" ,stagger:0.01})
      gsap.to(this.letters.office,{x:"-1500%",stagger:0.01})
      gsap.to(this.letters.services,{x:"-1500%",stagger:0.01})
      gsap.to(this.letters.references,{x:"-1500%",stagger:0.01})
      gsap.to(this.letters.contact,{x:"-1500%",stagger:0.01})
      setTimeout(() => {
        ScrollTrigger.refresh();
      }, 500);
    },
    changeColor(e){
      if(e.target.checked){
        this.$store.dispatch("setScreenMode",'light');
      }else{
        this.$store.dispatch("setScreenMode",'dark');
      }
    },
    changeLang(e){
      let currentLang = e.target.dataset.lang;
      if(currentLang === 'fr'){
        currentLang = 'en';
        e.target.dataset.lang = "en";
        // e.target.innerText = "EN | FR";
        sessionStorage.setItem("lang", "en");
      }else{
        currentLang = 'fr';
        e.target.dataset.lang = "fr"
        // e.target.innerText = "FR | EN";
        sessionStorage.setItem("lang", "fr");
      }
      this.$store.dispatch("setLang",currentLang);
    }
  },
  watch:{
    mode:{
      handler(mode){
        const r = document.querySelector(':root');
        if(mode === 'dark'){
          r.style.setProperty('--white', '#333');
          r.style.setProperty('--black', '#F7F7F7');
          r.style.setProperty('--filter', 'grayscale(1) contrast(0) brightness(0)');
          this.$refs.color_mode.innerText = this.lang === 'fr'? "Sombre":"Dark";
          sessionStorage.setItem("mode", "dark");
        }else{
          r.style.setProperty('--white', '#F7F7F7');
          r.style.setProperty('--black', '#333');
          r.style.setProperty('--filter', 'grayscale(1) contrast(0) brightness(2)');
          this.$refs.color_mode.innerText = this.lang === 'fr'? "Claire":"Light";
          sessionStorage.setItem("mode", "light");
        }
      }
    },
    lang:{
      handler(){
        if(this.mode === 'light'){
          this.$refs.color_mode.innerText = this.lang === 'fr'? "Claire":"Light";
        }else{
          this.$refs.color_mode.innerText = this.lang === 'fr'? "Sombre":"Dark";
        }

      }
    }
  }
}
</script>

<style scoped lang="scss">

@import "../../styles/includes.scss";

header{
  top: 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width:100%;
  height: var(--header-height);
  box-sizing: border-box;
  padding: 0 2.5%;
  z-index: 80;
}

.navigation{
  position: fixed;
  top: 0;
  left: 0;
  display:flex;
  align-items:center;
  box-sizing: border-box;
  height: 100vh;
  width: 0;
  transform:translateX(-100%);
  background: $white;
  z-index: 90;
  --height:0;
  contain: paint;
  @include tablet{      
    padding-left: 13.333vw;
  }
  &::before{
    content: "";
    position: absolute;
    top: 80px;
    left: 0;
    width: 100%;
    height: 0.8vw;
    background-color: #F7F7F7;
    mix-blend-mode: exclusion;
    @include smartphone-landscape{
      height: 0.3vw;
    }
    @include tablet{
      left: 14.15vw;
      top: auto;
      bottom: 0;
      width: 0.321vw;
      height: calc(var(--height) - 9.3vw);
    }
  }
  & .block{
    @include tablet{
      position: absolute;
      top: 0.1rem;
      left: 0;
      width: 13.167vw;
      height: 100%;
      background-color: $white;
      z-index: 92;
    }
  }
  & ul{
    display: flex;
    flex-direction: column;
    gap: clamp(5vw, 32px, 10vw);
    padding-left: 0;
    z-index: 91;
    width: 100%;
    margin-top: clamp(16px, 25% , 32px);
    @include smartphone-landscape{
        gap: 1rem;
        margin-top: 5rem;
        margin-left: 7rem;
    }
    @include tablet{
      padding-left: 3rem;
      margin-top: 8rem;      
      gap: 2vw;
    }
    & li{
      font-size: clamp(25px, 4.5vw, 80px);
      text-transform: uppercase;
      color: $black;
      @include smartphone-landscape{
        font-size: clamp(16px, 3.5vw, 24px);
      }
      & a{
      display: flex;
      flex-flow: row nowrap;
      align-items: center;
      }
      & span{
      display: flex;
      align-items: center;
      justify-content: center;
      width: 10vw;
      height: 10vw;
      clip-path: polygon(80% 100%, 100% 0%, 20% 0%, 0% 100%);
      aspect-ratio: 1/1;
      transform: translate(-1500%);
      text-align: center;
      @include smartphone-landscape{
        width: auto;
        height: auto;
      }
      @include tablet{
        width: 6vw;
        height: 6vw;
      }
      }
      &:hover span{
        background: $black;
        color: $white;
      }
    }
  }
}

.logo{
  position: fixed;
  mix-blend-mode: exclusion;
  width: 30.406vw;
  max-width: 130px;
  z-index: 100;
  @include tablet{
    width: 12%;
    max-width: 230px;
  }
  & svg{
    width: 100%;
    height: 100%;
    fill: #f7f7f7;
  }
}

.burger{
  position: fixed;
  right: 3vw;
  top: 1rem;
  display:flex;
  justify-content:center;
  align-items:center;
  width: calc(var(--header-height) / 2);
  aspect-ratio: 1/1;
  background-color: #f7f7f7;
  border-radius: 100px ;
  mix-blend-mode: exclusion;
  cursor: pointer;
  z-index: 100;
  @include tablet{
    top: 1.5rem ;
  }
  &:hover{
    background-color: $black;
    & svg{
      fill: $white;
    }
  }
  & svg{
    width: 40%;
    height: auto;
  }
}

.top-bar{
  position: absolute;
  top: 15%;
  left: 4%;
  display:flex;
  align-items:center;
  gap: 5%;
  width: 80%;
  max-width: 350px;
  color: $black;
  font-size: clamp(18px,2vw,25px);
  opacity: 0;
  visibility: hidden;
  @include smartphone-landscape{
    right: 1rem;
    left: initial;
    top: 70%;
    flex-flow: column;
    align-items: flex-start;
    width: auto;
    gap: 1rem;
  }
  @include tablet{
    top: 3.5rem;
    left: initial;
    right: 15%;
    width: 25%;
    justify-content: space-between;
  }
  & .lang{
    white-space: nowrap;
    cursor: pointer;
  }
  & .mode{
    display: flex;
    align-items: center;
  }
  & .toggle-btn{
    margin-right: 1rem;
  }
}

</style>
