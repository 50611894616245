<template>
  <LoaderView/>
  <h2 v-for="(title,index) in this.titles" :key="index" class="title" :class="title + '-title'">
    <template v-if="title === 'references'">
      <template v-if="this.lang === 'fr'">
        Références
      </template>
      <template v-else>
        {{title}}
      </template>
    </template>
    <template v-else>
      {{ title }}
    </template>
  </h2>

  <section class="section_projet">
    <template v-if="$apollo.queries.projets.loading">
      <div class="projet projet_1"></div>
      <div class="projet projet_2"></div>
      <div class="projet projet_3"></div>
      <div class="projet projet_4"></div>
      <div class="projet projet_5"></div>
      <div class="projet projet_6"></div>
    </template>
    <router-link v-else v-for="(projet,index) in this.projets" :key="index" class="projet" :class="'projet_'+(index+1)" :to="`/projet/${projet.slug}`">
      <img :src="projet.images[0].url">
      <div>
        <h4>{{ projet.title }}</h4>
      </div>
    </router-link>
  </section>

  <section ref="office_section" class="office-section">
    <section class="panel first">
      </section>
      <section id="office" class="panel office">
        <div class="image">
        </div>
        <div class="text">
          <Skeletor v-if="$apollo.queries.homepage.loading" as="p"/>
          <template v-else>
            <p v-if="this.lang === 'fr'" >{{ homepage.officeText }}</p>
            <p v-else >{{ homepage.localizations[0].officeText }}</p>
          </template>
        </div>
      </section>
      <section class="panel portrait">
        <div class="image">
        </div>
        <div class="text">
          <div>
            <h3>Gilles Rossier</h3>
            <h5 v-if="this.lang ==='fr'">Directeur des travaux diplômé</h5>
            <h5 v-else>Qualified works manager</h5>
          </div>
          <Skeletor v-if="$apollo.queries.homepage.loading" as="p"/>
          <template v-else>
            <p v-if="this.lang === 'fr'" >{{ homepage.portaitTexte }}</p>
            <p v-else >{{ homepage.localizations[0].portaitTexte }}</p>
          </template>
        </div>
      </section>
  </section>

  <section id="services" class="services-section">
    <div ref="scrolling_group_1" class="scrolling-group">
      <template v-if="!$apollo.queries.services.loading">
          <template v-for="(service, index) in this.services" :key="index">
            <div :id="'x-'+service.id" class="title" :class="{outlined: index % 2 === 1}" @mouseenter="handleMouseEnterOnService" @mousemove="handleMouseEnterOnService" @mouseleave="handleMouseLeaveOnService" >
              <h3 v-if="this.lang === 'fr'">{{ service.title }}</h3>
              <h3 v-else>{{ service.localizations[0].title }}</h3>
            </div>
            <div class="dot"></div>
        </template>
      </template>
    </div>
    <div ref="scrolling_group_2" class="scrolling-group">
      <template v-if="!$apollo.queries.services.loading">
          <template v-for="(service, index) in reversedServices" :key="index">
            <div :id="'x-'+service.id" class="title" :class="{outlined: index % 2 === 0}" @mouseenter="handleMouseEnterOnService" @mousemove="handleMouseEnterOnService" @mouseleave="handleMouseLeaveOnService" >
              <h3 v-if="this.lang === 'fr'">{{ service.title }}</h3>
              <h3 v-else>{{ service.localizations[0].title }}</h3>
            </div>
            <div class="dot"></div>
        </template>
      </template>
    </div>

    <template v-if="!$apollo.queries.services.loading">
      <div v-for="(service, index) in this.services" :key="index" :id="service.id" class="floating-service">
        <!-- <img :src="service.image.url" loading="lazy"> -->
        <div v-if="this.lang === 'fr'">
          <h4>{{ service.title }}</h4>
          <div v-html="service.description.html"></div>
        </div>
        <div v-else>
          <h4>{{ service.localizations[0].title }}</h4>
          <div v-html="service.localizations[0].description.html"></div>
        </div>
      </div>
    </template>

  </section>

  <section id="references" class="references-section">
    <div ref="veilLeft" class="veil left"></div>
    <div ref="veilRight" class="veil right"></div>
    <div class="references-block">
      <template v-if="!$apollo.queries.homepage.loading">
        <a v-for="(img,index) in this.homepage.logos" :key="index" :href="img.link" target="_blank">
          <img class="logo" :src="img.url" loading="lazy" >
        </a>
      </template>
    </div>
  </section>
  <div class="testify"></div>

  <FooterLayout/>
</template>

<script>
import gql from 'graphql-tag';
import gsap from 'gsap';
import { ScrollTrigger } from 'gsap/all';
import FooterLayout from "../components/layout/FooterLayout.vue";
import LoaderView from "../components/LoaderView.vue";
import { mapState } from 'vuex';
import { Skeletor } from 'vue-skeletor';

gsap.registerPlugin(ScrollTrigger);
ScrollTrigger.normalizeScroll(true);

export default {
  name: 'HomeView',
  components: {
    FooterLayout,
    LoaderView,
    Skeletor
},
  apollo: {
    projets: {
    query: gql`
      query projets {
        projets(last: 6, where: {isMainPage: true},locales: [fr,en]) {
          id
          title
          slug
          images {
            url
          }
        }
      }`,
    },
    homepage:{
    query: gql`query homepage {
        homepage(where: {id: "cltorhmluq7ef07w114sv0r8v" }, locales: [fr]) {
          id
          officeText
          portaitTexte
          logos(locales:[en]) {
            id
            alt
            url
            link
          }
          localizations{
            officeText
            portaitTexte
          }
        }
      }`,
    },
    services:{
      query: gql`
        query services {
          services(locales: [fr,en]) {
            id
            title
            description {
              html
            }
            localizations{
              title
              description { html }
            }
          }
        }
      `,
    }
  },
  computed: {
    ...mapState([
      'lang',
      'documentReady'
    ]),
    reversedServices() {
      return [...this.services].reverse();
    },
  },
  data() {
    return {
      titles: [
        "projets",
        "office",
        "services",
        "references",
        "contact"
      ],
      projets : null,
      sections : null,
      media : gsap.matchMedia(),
      gsapTimelines: [],
    }
  },
  mounted(){
    if(this.documentReady){
      this.setupAllAnimation();
    }
  },
  methods:{
    handleMouseEnterOnService(e){
      const x = e.clientX;
      const y = e.clientY;
      const id = e.target.id.slice(2);
      const width = gsap.getProperty(`#${id}`,'width');

      this.media.add("(min-width: 900px)",()=>{
        gsap.to(`#${id}`,{
          autoAlpha:1,
          x:x-width /2,
          y:y-width /2
        });
      });
    },
    handleMouseLeaveOnService(e){
      const id = e.target.id.slice(2);

      this.media.add("(min-width: 900px)",()=>{
        gsap.to(`#${id}`,{
        autoAlpha:0,
        });
      });
    },
    setupHorizontalScroll() {
      this.sections = gsap.utils.toArray(".panel");
      const horizontalScroll = gsap.to(this.sections, {
        xPercent: -100 * (this.sections.length - 1),
        ease: "none",
        scrollTrigger: {
          trigger: ".office-section",
          pin: true,
          scrub: 1,
          snap: false,
          end: () => "+=" + document.querySelector(".office-section").offsetWidth,
        }
      });
      this.gsapTimelines.push(horizontalScroll);
    },
    setupOfficeTitlesAnimation() {
      this.media.add("(min-width: 900px)",()=>{
        const OfficeTitleAnimation = gsap.timeline({
        scrollTrigger:{
          trigger: ".office-section",
          scrub:1,
          start: "top+=200px bottom",
          end: "bottom+=300% top",
          toggleActions: "play pause reset reset",
          preventOverlaps: true,
          fastScrollEnd:true,
        }})
        .to(".projets-title",{
          rotationX:'-90deg',
        },"0")
        .to(".office-title",{
          rotationX:'0deg',
        },"0.5")
        .to(".office-title",{
          x:"5vw",
        },"3")
        this.gsapTimelines.push(OfficeTitleAnimation);
      })

      this.media.add("(max-width: 899px)",()=>{
        const OfficeTitleAnimation = gsap.timeline({
          scrollTrigger:{
            trigger: ".office-section",
            scrub:1,
            start: "top center",
            end:"top top",
            toggleActions: "play none none reverse",
            preventOverlaps: true,
            fastScrollEnd:true,
          },
        })
        .to(".projets-title",{
          y: "-24%",
          rotationX:'90deg',
        },"0")
        .to(".office-title",{
          rotationX:'0deg',
        },"1")
        this.gsapTimelines.push(OfficeTitleAnimation);
      })
    },
    setupServicesTitlesAnimation() {
      this.media.add("(min-width: 900px)",()=>{
        const ServicesTitleAnimation = gsap.timeline({
          scrollTrigger:{
            trigger: ".services-section",
            start: "top-=10% bottom",
            end: "top top",
            scrub:1,
            toggleActions: "play none none reverse",
            preventOverlaps: true,
            fastScrollEnd:true,
          }
        })
        .to(".office-title",{
          rotationX:'90deg',
        },"0")
        .to(".services-title",{
          rotationX:'0deg',
        },"1")
        this.gsapTimelines.push(ServicesTitleAnimation);
      })

      this.media.add("(max-width: 899px)",()=>{
        const ServicesTitleAnimation = gsap.timeline({
          scrollTrigger:{
            trigger: ".services-section",
            start: "top bottom",
            end: "top top+=20%",
            scrub:2,
            preventOverlaps: true,
            fastScrollEnd:true,
          }
        })
        .to(".office-title",{
          rotationX:'90deg',
        },"0")
        .to(".services-title",{
          rotationX:'0deg',
        },"1")
        .to(".services-title",{
          y:"-35svh"
        },"2")
        this.gsapTimelines.push(ServicesTitleAnimation);
      })

    },
    setupServicesAnimation() {
      this.media.add("(min-width: 900px)",()=>{
        const ServicesAnimation = gsap.timeline({
          scrollTrigger:{
            trigger: ".services-section",
            start: "center+=10vw center-=15%",
            end: "bottom top",
            scrub:1,
            pin:true,
          }
        })
        .to(this.$refs.scrolling_group_1,{
          x:"-80%",
        },"0")
        .to(this.$refs.scrolling_group_2,{
          x:0,
        },"0");
        this.gsapTimelines.push(ServicesAnimation);
      })

      this.media.add("(max-width: 899px)",()=>{
        const ServicesAnimation = gsap.timeline({
          scrollTrigger:{
            trigger: ".services-section",
            start: "top top+=25%",
            end: "bottom+=800% top",
            scrub:1,
            pin:true,
          }
        })
        .to([this.$refs.scrolling_group_1,this.$refs.scrolling_group_2],{
          autoAlpha:1
        },"appearing")
        .to(this.$refs.scrolling_group_1,{
          x:"-150%",
          duration:10
        },"appearing+=0.25")
        .to(this.$refs.scrolling_group_2,{
          x:0,
          duration:10
        },"appearing+=0.25")
        .to(".floating-service",{
          y:"-40%",
          stagger:2,
        },"appearing+=0.25");
        this.gsapTimelines.push(ServicesAnimation);
      });
    },
    setupReferencesAnimation() {
      this.media.add("(min-width: 900px)",()=>{
        const ReferencesAnimation = gsap.timeline({
          scrollTrigger:{
            trigger: ".references-section",
            start: "top-=10% bottom",
            end: "top+=35% top",
            scrub:2,
            toggleActions: "play none none reverse",
            preventOverlaps: true,
            fastScrollEnd:true,
          }
        })
        .to(".services-title",{
          rotationX:'90deg',
        },"0")
        .to(".references-title",{
          rotationX:'0deg',
        },"3")
        .to([this.$refs.veilLeft,this.$refs.veilRight],{
          width:0,
        },"3")
        .to(".references-block img",{
          autoAlpha:1,
        },"4")
        .to(".references-block img",{
          autoAlpha:0,
        },"5");
        this.gsapTimelines.push(ReferencesAnimation);
      })

      this.media.add("(max-width: 899px)",()=>{
        const ReferencesAnimation = gsap.timeline({
          scrollTrigger:{
            trigger: ".references-section",
            start: "top bottom-=30%",
            end: "bottom-=10% top",
            scrub:2,
            toggleActions: "play none none reverse",
            preventOverlaps: true,
            fastScrollEnd:true,
          }
        })
        .to(".services-title",{
          rotationX:'90deg',
        },"1")
        .to(".references-title",{
          rotationX:'0deg',
        },"2")
        .to([this.$refs.veilLeft,this.$refs.veilRight],{
          width:0,
        },"3")
        .to(".references-block img",{
          autoAlpha:1,
          stagger:1
        },"2")
        .to(".references-block img",{
          autoAlpha:0,
          stagger:1
        },"5");
        this.gsapTimelines.push(ReferencesAnimation);
      })
    },
    setupContactAnimation(){
      this.media.add("(min-width: 900px)",()=>{
        const ContactAnimation = gsap.timeline({
          scrollTrigger:{
            trigger: ".testify",
            start: "top bottom-=20%",
            end: "top top",
            scrub:1,
            toggleActions: "play none none reverse",
            preventOverlaps: true,
            fastScrollEnd:true,
          }
        })
        .to(".references-title",{
          rotateX:'90deg',
        },"0")
        .to(".contact-title",{
          rotateX:0,
        },"1")
        this.gsapTimelines.push(ContactAnimation);
      })

      this.media.add("(max-width: 899px)",()=>{
        const ContactAnimation = gsap.timeline({
          scrollTrigger:{
            trigger: ".testify",
            start: "center center",
            end: "top top",
            scrub:1,
            toggleActions: "play none none reverse",
            preventOverlaps: true,
            fastScrollEnd:true,
          }
        })
        .to(".references-title",{
          rotateX:'90deg',
        },"0")
        .to(".contact-title",{
          rotateX:0,
        },"1")
        .to(".contact-title",{
          y:"-30vh",
        },"1");
        this.gsapTimelines.push(ContactAnimation);
      })
    },
    setupAllAnimation(){
      this.setupHorizontalScroll();
      this.setupOfficeTitlesAnimation();
      this.setupServicesTitlesAnimation();
      this.setupServicesAnimation();
      this.setupReferencesAnimation();
      this.setupContactAnimation();
      window.addEventListener("resize",()=>this.refreshOnResize)
      ScrollTrigger.refresh();
    },
    cleanupAnimations() {
      this.gsapTimelines.forEach(timeline => {
        if (timeline.scrollTrigger) {
          timeline.scrollTrigger.kill();
        }
        timeline.kill();
      });
      this.gsapTimelines = [];
      window.removeEventListener("resize",()=>this.refreshOnResize);
    },
    refreshOnResize() {
      ScrollTrigger.refresh();
    },
  },
  beforeUnmount() {
    this.cleanupAnimations();
  },
  watch:{
    documentReady:{
      handler(documentReady){
        if(documentReady){
          this.setupAllAnimation();
        }
      }
    },
  }
}
</script>

<style lang="scss">
@import "../styles/includes.scss";

/*------PROJETS------*/

h2{
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
  width: 100%;
  font-size: clamp(54px, 13vw, 340px);
  margin: auto;
  z-index: 2;
  @include tablet{
    width: auto;
  }
  &.office-title{
    transform: translateX(-50%) translateY(-150%) rotateX(-90deg);
    z-index: 8;
    @include tablet{
      transform: translateX(-50%) translateY(-108%) rotateX(-90deg);
      z-index: 2;
    }
  }
  &.services-title{
    transform: translateX(-50%) translateY(-108%) rotateX(90deg);
  }
  &.references-title{
    top: 15%;
    transform: translateX(-50%) rotateX(-90deg);
    z-index: 8;
    @include tablet{
      top: 25%;
      z-index: 2;
      width: 90%;
      transform: translateX(-50%) translateY(-25%) rotateX(-90deg);
    }
  }
  &.contact-title{
    transform: translateX(-50%) translateY(-50%) rotateX(-90deg);
    @include tablet{
      top:40%;
      transform: translateX(-50%) translateY(-109%) rotateX(-90deg);
    }
  }
}

.section_projet{
  position: relative;
  top:0;
  z-index: 0;
  z-index: 5;
  height: auto;
  padding-bottom:60%;
  @include tablet{
    padding-bottom:0;
  }
  & .projet{
    position: relative;
    display: block;
    background-color: $white;
    transition: all 0.5s ease;
    contain: paint;
    aspect-ratio: 3/2;
    &:hover{
      transition: all 0.5s ease;
    }
    &:hover>div{
      opacity: 1;
      visibility: visible;
      transition: all 0.5s ease;
    }
    &:hover>img{
      transform: scale(1.2);
      transition: all 0.5s ease;
    }
    & img{
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: center;
      transition: all 0.5s ease;
    }
    &>div{
      position:absolute;
      top: 50%;
      left: 50%;
      transform: translateX(-50%) translateY(-50%);
      display:flex;
      flex-flow: column nowrap;
      align-items:center;
      justify-content: center;
      height: 100%;
      width: 100%;
      color: $black;
      opacity: 0;
      visibility: hidden;
      transition: all 0.5s ease;
      resize: horizontal;
      container-type: size;
      & h5{
        font-size: clamp(10px, 6cqw, 24px);
        margin-top: 5cqw;
      }
      &:before{
        content: "";
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        background: $white;
        opacity: 0.6;
        z-index: -1;
      }
    }
  }
  &>.projet_1{
    top: 0%;
    left: 47%;
    width: clamp(175px, 16.302vw, 313px);
    height: auto;
    clip-path: polygon(70% 100%, 100% 0%, 30% 0%, 0% 100%);
    @include tablet{
      left: 60%;
      transform: translateY(-50%);
    }
    @include tablet-portrait{
      transform: translateY(-20%);
      width: clamp(175px, 26.302vw, 313px);
    }
  }
  &>.projet_2{
    top: 0% ;
    left: 1% ;
    transform: translateY(65%);
    width: 26.71vw;
    width: clamp(300px, 26.71vw, 513px);
    height: auto;
    clip-path: polygon(100% 100%, 70% 0%, 0% 0%, 30% 100%);
    z-index: 1;
    @include tablet{
      transform: translateY(-0%);
    }
    @include tablet-portrait{
      transform: translateY(70%);
      width: clamp(300px, 46.71vw, 513px);
    }
  }
  &>.projet_3{
    top: 0% ;
    left: 55% ;
    transform: translateY(100%);
    width: clamp(175px, 25.260vw, 485px);
    height: auto;
    clip-path: polygon(70% 100%, 100% 0%, 30% 0%, 0% 100%);
    z-index: 3;
    @include tablet{
      left: 70% ;
      transform: translateY(-50%);
    }
    @include tablet-portrait{
      left: 52%;
      transform: translateY(50%);
      width: clamp(175px, 45.260vw, 485px);
    }
  }
  &>.projet_4{
    top: 0% ;
    left: 0% ;
    transform: translateY(90%);
    width: clamp(265px, 21.354vw, 410px);
    clip-path: polygon(100% 100%, 70% 0%, 0% 0%, 30% 100%);
    height:auto;
    z-index: 3;
    @include tablet{
      left: 20% ;
      transform: translateY(-30%);
    }
    @include tablet-portrait{
      left: 13%;
      transform: translateY(80%);
      width: clamp(265px, 41.354vw, 410px);
    }
  }
  &>.projet_5{
    top: 0% ;
    left: 23% ;
    transform: translateY(100%);
    width: clamp(300px, 30.469vw, 585px);
    clip-path: polygon(100% 100%, 70% 0%, 0% 0%, 30% 100%);
    height: auto;
    z-index: 1;
    @include tablet{
      left: 60% ;
      transform: translateY(-70%);
    }
    @include tablet-portrait{
      left: 40%;
      transform: translateY(70%);
      width: clamp(300px, 50.469vw, 585px);
    }
  }
  &>.projet_6{
    top: 0% ;
    left: 6% ;
    transform: translateY(140%);
    width: clamp(265px, 21.354vw, 410px);
    clip-path: polygon(70% 100%, 100% 0%, 30% 0%, 0% 100%);
    height: auto;
    z-index: 1;
    @include tablet{
      transform: translateY(-130%);
    }
    @include tablet-portrait{
      transform: translateY(80%);
      width: clamp(175px, 45.260vw, 485px);
    }
  }
}

/*------OFFICE------*/


.office-section {
  width: 300%;
  height: 100vh;
  display: flex;
  flex-wrap: nowrap;
  z-index: 5;
  & .panel{
    height: 100svh;
    width: 100vw;
    display:flex;
    flex-direction: column;
    align-items:flex-end;
    @include smartphone-landscape{
      flex-direction: row;
    }
    @include tablet{
      flex-direction: row;
    }
    @include tablet-portrait{
      flex-direction: column;
    }
    &.office{
      & .image{
        width: 100%;
        height: 45%;
        background-image: url("../assets/img/chantier-ensoleillé.jpg");
        background-position: center;
        background-size: cover;
        background-repeat: no-repeat;
        @include smartphone-landscape{
          height: 100%;
        }
        @include tablet{
          background-size: cover;
          width: 120%;
          height: 80%;
        }
        @include tablet-portrait{
          width: 100%;
          height: 60%;
        }
      }
      & .text{
        width: 100%;
        height: 55%;
        display:flex;
        align-items: flex-start;
        line-height: 1.5;
        color: $black;
        @include smartphone-landscape{
          height: 100%;
        }
        @include tablet{
          width: 70%;
          height: auto;
          padding: 0 2rem;
        }
        @include tablet-portrait{
          width: 100%;
          height: 40%;
        }
        & p{
          padding:10% 5%;
          @include tablet{
            padding: 0;
          }
        }
      }
    }
    &.portrait{
      & .image{
        width: 100%;
        height: 45%;
        background-image: url("../assets/img/Gilles-Rossier.jpg");
        background-position: center;
        background-size: cover;
        background-repeat: no-repeat;
        @include smartphone-landscape{
          height: 100%;
        }
        @include tablet{
          width: 30%;
          height: 80%;
        }
        @include tablet-portrait{
          width: 100%;
          height: 60%;
        }
      }
      & .text{
        justify-content: flex-start;
        display: flex;
        flex-direction: column;
        height: 55%;
        line-height: 1.5;
        padding: 5%;
        color: $black;
        @include smartphone-landscape{
          height: 100%;
        }
        @include tablet{
          justify-content: space-between;
          width: 60%;
          height: 45%;
          padding: 0 2rem;
        }
        @include tablet-portrait{
          width: 100%;
          height: 40%;
        }
        & p{
          padding: 5% 0;
          @include tablet{
            padding: 0;
          }
        }
      }
    }
    & p{
      font-size:clamp(16px, 1.5vw, 28px);
    }
  }
}

/*------SERVICES------*/

.services-section{
  position: relative;
  padding-bottom: 30svh;
  z-index: 5;
  @include tablet{
    padding-bottom: 0;
    padding-top: 30vw;
  }
  @include tablet-portrait{
    padding-top: 60vh;
  }
  & .scrolling-group{
    position: relative;
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    gap: 1.5vw;
    padding: 3vw 0;
    opacity: 0;
    visibility: hidden;
    @include tablet{
      opacity: 1;
      visibility: visible;
    }
    &>.title{
      height: 100%;
      & *{
          pointer-events: none;
      }
      & h3{
        font-size: clamp(20px, 4.4vw, 85px);
        white-space: nowrap;
        color: $black;
        mix-blend-mode: exclusion;
        @include tablet-portrait{
          font-size: clamp(30px, 4.2vw, 85px);
        }
      }
      &.outlined h3 {
        -webkit-text-stroke-width: clamp(1px, 0.2vw, 2px);
        -webkit-text-stroke-color: $black;
        color: transparent;
      }
    }
    &>.dot{
      display:flex;
      justify-content:center;
      align-items:center;
      height: 1vw;
      aspect-ratio: 1/1;
      border-radius: 50px;
      background-color: $black;
    }
  }
  & .scrolling-group:nth-child(1){
    transform: translateX(-0%);
  }
  & .scrolling-group:nth-child(2){
    transform: translateX(-80%);
  }
  & .floating-service{
        position:fixed;
        top: 95%;
        left: 0%;
        transform: translateY(250%);
        width: 100%;
        height: auto;
        min-height: 65svh;
        box-sizing: border-box;
        background: $black;
        color: $white;
        contain: paint;
        pointer-events: none;
        @include tablet{
          top: 0%;
          display: flex;
          flex-direction: column;
          justify-content: center;
          transform: inherit;
          gap: 5%;
          width: auto;
          height: auto;
          aspect-ratio: 1.6/1;
          clip-path: polygon(80% 100%, 100% 0%, 20% 0%, 0% 100%);
          box-sizing: content-box;
          padding: 0 15%;
          opacity: 0;
          visibility: hidden;
        }
        &:before{
          content: "";
          position:absolute;
          top: 50%;
          left: 50%;
          transform: translateX(-50%) translateY(-50%);
          width: 100%;
          height: 100%;
          background-color: $black;
          opacity: 0.5;
          z-index: 4;
          @include tablet{
            border-radius: 500px;
          }
        }
        &>img{
          position: absolute;
          width: 100%;
          height: 100%;
          object-fit: cover;
          z-index: 3;
          @include tablet{
            display: none;
          }
        }
        &>div{
          display: flex;
          flex-direction: column;
          margin-top: 4rem;
          gap: 2rem;
          @include smartphoneMini{
            margin-top: 2rem;
          }
          @include tablet{
            padding-left: 2rem;
            margin-top: 0;
          }
        }
        & h4{
          font-size: clamp(18px,1.5vw,35px);
          text-transform: uppercase;
          color: $white;
          z-index: 4;
          position: relative;
          margin: 0 5%;
          @include smartphoneMini{
            font-size: 16px;
          }
          @include tablet{
            margin: 0;
          }
        }
        & p{
          font-size: clamp(14px,4.2vw,22px);
          color: $white;
          z-index: 4;
          position: relative;
          margin: 0 5%;
          @include tablet{
            margin: 0;
          }
        }
  }
}

/*------REFERENCES------*/

.references-section{
  position: relative;
  display:flex;
  justify-content:center;
  align-items:flex-end;
  min-height: 180svh;
  max-height: 1000px;
  contain: paint;
  background-color: $black;
  scroll-padding-top: 200vh;
  @include tablet{
    min-height: 140svh;
    align-items:center;
    max-height: 1300px;
  }
  & .veil{
    position:absolute;
    top: 0%;
    height: 100%;
    width: 50%;
    background-color: $white;
    z-index: -1;
    &.left{
      left: 0;
    }
    &.right{
      right: 0;
    }
  }
  & .references-block{
    display: flex;
    flex-flow: column nowrap;
    align-items: center;
    justify-content: space-between;
    gap: 10vh;
    width: 55%;
    height: auto;
    margin-top: 25vw;
    font-size: clamp(40px,5vw,80px);
    color: $white;
    text-align: center;
    @include tablet{
      gap: 0;
      flex-flow: row nowrap;
      width: 75%;
    }
    & a {
      min-width: 150px;
      max-width: 330px;
      width: 15%;
      &:hover img{
        mix-blend-mode: overlay;
      }
    }
    & img{
      width: 100%;
      opacity:0;
      visibility: hidden;
      filter: $filter-logo;
      @media (prefers-color-scheme: dark) {
        filter:$filter-logo;
      }
    }
  }
}

.testify{
  height: 40vh;
  padding-top:100svh;
  background-color: $black;
  @include tablet{
    padding-top:0;
  }
}
</style>
